<template>
  <base-section id="posts">

    <base-section-heading
        color="grey lighten-2"
        :title="$t('latest_posts_and_stories')"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="post of posts"
          :key="post.Title"
          cols="12"
          md="4"
        >
          <base-post-card v-bind="post" />
        </v-col>
      </v-row>
    </v-container>

  </base-section>

</template>

<script>

  import axios from 'axios';
  import BasePostCard from '@/components/base/PostCard'

  export default {
    name: 'SectionPosts',
    components: {
      BasePostCard,
    },
    data: () => ({
      posts: [],
      isLoading: false,
    }),
    watch:{
      // eslint-disable-next-line no-unused-vars
      $route (to, from){
        this.loadData();
      }
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.isLoading = true;
        axios.get(`https://strapi.ethosevaluate.net/api/posts?fields[0]=Title&fields[1]=Slug&fields[2]=Lead&fields[3]=Date&sort[0]=Date:desc&pagination[page]=1&pagination[pageSize]=3&populate=Image&locale=${this.$i18n.locale}`).then(res => {
          this.posts = Array.isArray(res.data?.data) ? res.data.data.map(post => ({
            lang: this.$i18n.locale,
            date: post.attributes?.Date,
            title: post.attributes?.Title,
            lead: post.attributes?.Lead,
            image: post.attributes?.Image?.data?.attributes?.formats?.small?.url,
            slug: post.attributes?.Slug,
            id: post.id,
          })) : [];
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          this.isLoading = false;
          window.prerenderReady = true;
        });
      },
    },
  }
</script>
